<template>
  <div class="GlobalTable" v-if="refresh">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" :pageSize="page.pageSize"
      @handleCurrentChange="handleCurrentChange">
      <el-table-column label="门店商户" slot="senderCompanyName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.senderCompanyName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="发货门店" slot="senderStoreName" align="center">
        <template slot-scope="{ row }">
          <span> {{ row.senderStoreName }}</span>
        </template>
      </el-table-column>

      <el-table-column label="发货快递" slot="expressCode" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.expressCode != '--'">
            {{ row.expressCode == "jd" ? "京东物流" : "顺丰物流" }}</span>
          <span v-else>线下发货</span>
        </template>
      </el-table-column>
      <el-table-column label="下单通道" v-if="state != 100" slot="singleChannel" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.channel"> {{ row.channel == 'dl' ? "鼎霖" : "顺丰快递管家" }}</span>
          <span v-else> --</span>
        </template>
      </el-table-column>
      <el-table-column label="下单方式" slot="callerChannel" align="center">
        <template slot-scope="{ row }">
          <span>
            {{ optionsFilter(row.callerChannel, callerChannelOptions) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="取消操作人" slot="cancelUserName" align="center">
        <template slot-scope="{ row }">
          <div style="display: flex; flex-direction: column; align-items: center">
            <div>
              <span>{{ row.cancelUserName }}</span>
              <span v-if="row.cancelUserAcount != '--'">{{
                "-" + row.cancelUserAcount
              }}</span>
            </div>
            <span>
              ({{
                optionsFilter(row.cancelOrigin, cancelOriginOptions) || "--"
              }})
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="发货联系人" slot="senderName" align="center">
        <template slot-scope="{ row }">
          <div style="display: flex; flex-direction: column; align-items: center">
            <span> {{ row.senderName }}-{{ row.senderMobile }} </span>
            <span class="channeltype3">
              {{
                row.senderIsMajor
                  ? "创建人"
                  : row.senderType == "01"
                    ? "子账号"
                    : row.senderType == "02"
                      ? "总监"
                      : row.senderType == "03"
                        ? "店长"
                        : "店员"
              }}
            </span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="发货旧机" slot="parcelQuantity" align="center">
        <!-- <template slot-scope="{ row }">
          <div style="display:flex;flex-direction: column;justify-content: center;align-items: center;">
            <span style="color: #0981ff; cursor: pointer;text-decoration:underline;" @click="fahuo(row)">
              {{ row.allCount }}</span
            >
            <span style="color: red;">{{ row.waitDeliveryNum == '--' ? 0 : row.waitDeliveryNum }}台待收货</span>
          </div>
        </template> -->
        <template slot-scope="{ row }">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            ">
            <span style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              " @click="fahuo(row)">
              {{ row.allCount }}</span>
            <span :style="{ color: row.waitDeliveryNum == '0' ? '#666' : 'red' }">{{
              row.waitDeliveryNum == "0"
                ? "暂无待收货"
                : row.waitDeliveryNum + "台待收货"
            }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="发货总价值" slot="allCost" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.allCost }}元</span>
        </template>
      </el-table-column>

      <el-table-column label="超时未取件标记" slot="noPickDay" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.noPickDay != '0'" style="color: red">超{{ row.noPickDay }}天未取件</span>
          <span v-else>正常</span>
        </template>
      </el-table-column>

      <el-table-column label="发货拍照" align="center" slot="sendProof">
        <template slot-scope="{ row }">
          <div class="images-box" v-if="row.sendProof != '--' || row.sendProof1 != '--'">
            <img :src="row.sendProof || row.sendProof1" alt="" />
            <span @click="openImgBox(row)" class="text-down">共<span>{{
              row.sendProof != "--" && row.sendProof1 != "--" ? 2 : 1
            }}</span>
              张</span>
          </div>
          <div v-else>暂无图片</div>
        </template>
      </el-table-column>

      <el-table-column v-if="[1, 2, 6, 7].includes(state)" label="实际快递状态" slot="stateReal" align="center">
        <template slot-scope="{ row }">
          <template v-if="row.expressCode == 'jd'">
            {{ optionsFilter(row.stateReal, stateRealOptions) }}-{{
              row.jdActionDesc == "--" ? "-" : row.jdActionDesc
            }}
          </template>
          <template v-else>
            {{ optionsFilter(row.stateReal, stateRealOptions) || "--" }}
          </template>
        </template>
      </el-table-column>
      <el-table-column v-if="state == 7" label="是否为强制取消" slot="isForceCancel" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.isForceCancel" style="color: red">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>

      <el-table-column v-if="state != '100'" :width="state == '7' || '6' ? 150 : 320" label="操作" fixed="right"
        slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-button size="mini" type="primary" round @click="handleBtn(row, 'see')">查看物流详情
          </el-button>
          <el-button v-if="row.state == '2'" size="mini" type="warning" round @click="handleBtn(row, 'cance')">取消寄件
          </el-button>
          <el-button style="margin-top: 5px" v-if="row.state == '2'" size="mini" type="danger" round
            @click="canCelBtn(row)">强制取消
          </el-button>
          <el-button style="margin-top: 5px" size="mini" type="danger" round @click="canDetailCelBtn(row)"
            v-if="row.state == 7 && row.isForceCancel">取消详情
          </el-button>
          <el-button size="mini" type="success" round @click="$refs['logisticsInquiryStatusDia'].open(row)">
            查询物流状态
          </el-button>
        </template>
      </el-table-column>

      <el-table-column width="100" label="物流费用" slot="allFee" align="center">
        <template slot-scope="{ row }">
          <span>￥{{ row.allFee }}</span>
        </template>
      </el-table-column>

      <el-table-column v-if="state == 2" label="超时未签收标记" slot="noSignDay" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.noSignDay != '0'" :style="{ color: row.noSignDay > 3 ? 'red' : '#666' }">已发货{{ row.noSignDay
            }}天</span>
          <span v-else>正常</span>
        </template>
      </el-table-column>

      <el-table-column v-if="state == 100" label="已发货标记" slot="noSignDay" align="center">
        <template slot-scope="{ row }">
          <span v-if="row.noSignDay != '0'" :style="{ color: row.noSignDay > 3 ? 'red' : '#666' }">已发货{{ row.noSignDay
            }}天</span>
          <span v-else>正常</span>
        </template>
      </el-table-column>

      <el-table-column slot="intoOldNum" align="center" width="140px" :filters="[
        { text: '异常', value: '01' },
        { text: '正常', value: '00' },
      ]" :filter-method="filterTag" filter-placement="bottom-end">
        <template slot="header">
          <span>仓库入库旧机</span>
          <img src="https://img.fanxinghuishou.cn/20240109/084440477.png" width="10px" height="10px" />
        </template>
        <template slot-scope="{ row }">
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #0981ff;
            ">
            <span style="
                color: #0981ff;
                cursor: pointer;
                text-decoration: underline;
              " @click="intoMachine(row)">{{ row.intoOldNum == "--" ? 0 : row.intoOldNum }}</span>
            <span v-if="row.receiverMerchantName == '繁星分转中心'" :style="{
              color: row.intoOldNumState != '00' ? 'red' : '#0981ff',
            }">{{ row.intoOldNumState == "00" ? "一致" : "异常" }}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="预约时间" slot="sendTimeRegion" align="center">
        <template slot-scope="{ row }">
          <span style="color: red">{{ row.sendTimeRegion }}</span>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 备注弹出框 -->
    <!--弹框-->
    <el-dialog class="acc_dialog" title="物流详情" :visible.sync="Logisticsdetails" :close-on-click-modal="false"
      width="810px">
      <div v-loading="loading2">
        <div class="fzs18 b25">快递单号：{{ Logisticsarray.expressNo }}</div>

        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
          ">
          <div class="one">
            <div class="fzs18 b03">{{ Logisticsarray.senderCompanyName }}</div>
            <div class="fzs14">{{ Logisticsarray.senderStoreName }}</div>
          </div>

          <div class="one_img">
            <img src="../../../assets/images/jiantouleft.png" alt="" />
          </div>

          <div class="one">
            <div class="fzs18 b03">
              {{ Logisticsarray.receiverMerchantName }}
            </div>
          </div>
        </div>

        <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
          ">
          <div class="two">
            <div class="twoinfo">寄件人信息</div>
            <div class="twophone">
              {{ Logisticsarray.senderName }}-{{ Logisticsarray.senderMobile }}
            </div>
            <div>
              {{
                Logisticsarray.senderAddress +
                Logisticsarray.senderAddressDetail
              }}
            </div>
          </div>
          <div class="two">
            <div class="twoinfo">收件人信息</div>
            <div class="twophone">
              {{ Logisticsarray.receiverName }}-{{
                Logisticsarray.receiverMobile
              }}
            </div>
            <div>
              {{
                Logisticsarray.receiverAddress +
                Logisticsarray.receiverAddressDetail
              }}
            </div>
          </div>
        </div>
        <div class="center_router">
          <span style="color: #0981ff;">{{ Logisticsarray.routeDesc || "--" }}</span>
          <span style="color: #333333;">{{ Logisticsarray.routeTime || "--" }}</span>
        </div>
        <div class="loacinfo">物流信息</div>

        <div class="three">
          <el-timeline v-if="
            this.Logisticsarray.logistics &&
            this.Logisticsarray.logistics.length
          ">
            <el-timeline-item v-for="(activity, index) in Logisticsarray.logistics" :key="index"
              :timestamp="activity.opeRemark">
              {{ activity.opeTime }}
            </el-timeline-item>
          </el-timeline>
          <el-empty v-else style="margin-top: -40px" description="暂无物流信息"></el-empty>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="Logisticsdetails = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog class="allot-dialog" title="订单列表" :visible.sync="handleShow" :close-on-click-modal="false" width="980px"
      @closed="allotDialogClosed">
      <GlobalForm class="global-form" :init-data="initData" :form-item-list="redenvelopeslist" :inline="true"
        :labelWidth="100" round @handleConfirm="redenvelopeslistFn" confirmBtnName="查询">
        <template slot="AddSlot">
          <el-checkbox v-model="expressMarkState" true-label="10" false-label=""
            @change="redenvellistFn(1)">查询已取消关联该快递的订单</el-checkbox>
        </template>
      </GlobalForm>

      <GlobalTable ref="GlobalTable" v-loading="loading3" :columns="redenvelopeslisttitle" :data="this.redenvedata"
        :currentPage="page3.pageNum" :total="page3.total" @handleCurrentChange="redenvellistFn">
        <el-table-column label="订单编号" slot="orderNo" align="center">
          <template slot-scope="{ row }">
            {{ row.orderNo }}
            <p v-if="row.expressMarkState == '10'">
              <el-tag size="mini">已取消关联</el-tag>
            </p>
          </template>
        </el-table-column>
        <el-table-column slot="phoneName" label="机型" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.isElderlyMachine">功能机/老年机</div>
            <div v-else-if="!row.canStartingUp">不能开机，无法获取</div>
            <div v-else-if="!row.isElderlyMachine && row.canStartingUp">
              <span v-if="!row.phoneName && !row.phoneMemory && !row.phoneStorage">--</span>
              <div v-else>
                <span>{{ row.phoneName }}</span>
                <span v-if="row.phoneMemory && row.phoneStorage">({{ row.phoneMemory + "+" + row.phoneStorage }})</span>
                <span v-else-if="row.phoneMemory || row.phoneStorage">({{ row.phoneMemory || row.phoneStorage }})</span>
                <!-- <span v-else-if="!row.phoneMemory && !row.phoneStorage"></span>
                <span v-else>({{row.phoneMemory + "" + row.phoneStorage}})</span> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态" slot="state" align="center">
          <template slot-scope="{ row }">
            <span>
              <span>{{
                row.state == "00"
                  ? "待确认"
                  : row.state == "01"
                    ? "已确认"
                    : row.state == "02"
                      ? "待支付"
                      : row.state == "03"
                        ? "已绑码"
                        : row.state == "04"
                          ? "已收货"
                          : row.state == "05"
                            ? "降价收货"
                            : row.state == "10"
                              ? "已取消"
                              : row.state == "20"
                                ? "已作废"
                                : "已退回"
              }}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="旧机条码" slot="barCode" align="center">
          <template slot-scope="{ row }">
            <div v-if="row.barCode != null" class="column">
              <span> {{ row.barCode }}</span>
              <span>繁星码</span>
            </div>
            <div v-else-if="row.orderUnique != null" class="column">
              <span> {{ row.orderUnique }}</span>
              <span>唯一码</span>
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="补充发货凭证" slot="supplement" align="center">
          <template slot-scope="{ row }">
            <div class="images-box" v-if="row.deliverGoodImages.length>0">
              <img :src="row.deliverGoodImages[0].sampleImage" alt="" />
              <span @click="openImgBox1(row)" class="text-down">共<span>{{
                row.deliverGoodImages.length
                  }}</span>
                张</span>
            </div>
            <div v-if="row.deliverGoodImages.length==0" class="column">
              <span>未上传凭证</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" slot="caozuo" align="center">
          <template slot-scope="{ row }">
            <el-button size="mini" type="primary" round @click="goDetails(row)">订单详情
            </el-button>
          </template>
        </el-table-column>
      </GlobalTable>
      <!-- 入账区域END -->
    </el-dialog>

    <el-dialog title="操作提示" :visible.sync="cancelShow" :close-on-click-modal="false" width="550px"
      @closed="canceClosed">
      <div class="cance-tip">
        <div>
          说明：请先跟门店确认后再操作，仅支持取消运输中的订单，不能保证取消成功
        </div>
        <div class="tip-sure">是否确认取消寄件该运单？</div>
      </div>
      <div class="command">
        <div style="width: 88px">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="canceClosed">取消</el-button>
        <el-button type="primary" :loading="canceBtnLoading" @click.native="canceSubmit">确定</el-button>
      </span>
    </el-dialog>

    <!-- 仓库入库旧机 -->
    <DialogTbale @onClose="onLockClose" :loading="expediteLoading" :show="expediteVisible" title="仓库入库旧机"
      :columns="expediteTraceLogColumns" :data="dialogExpediteList" :currentPage="expediteParams.pageNum"
      :total="expediteParams.total" :pageSize="10" :width="1100">
      <el-table-column label="是否对应发货旧机" slot="isSendExpress" align="center">
        <template slot-scope="{ row }">
          <span :style="{ color: row.isSendExpress == true ? '#0981ff' : 'red' }">{{ row.isSendExpress == true ? "是" :
            "否" }}</span>
        </template>
      </el-table-column>
    </DialogTbale>
    <!-- 强制取消退单 -->
    <GlobalDialog title="强制取消运单" :show="isCancel" @resetPopupData="cancelClose" @onClose="cancelClose" top="8%"
      @submitPopupData="cancelSubmit" :width="700">
      <div class="cancel_dialog">
        <h2>
          说明：强制取消订单后，运单在繁星状态变为已取消，方便店员操作退单或线下发货
        </h2>
        <p>是否确认强制将运单状态变为已取消状态？</p>
        <div class="code_box">
          <el-form>
            <el-form-item size="medium" label="动态口令" label-width="80px">
              <el-input v-model="cancelCode" placeholder="请输入谷歌动态口令"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 订单未全部收货  -->
        <div class="cancel_table" v-if="cancelOrder.length">
          <p class="cancel_text">
            关联以下订单未收货，操作前请先选择取消原因：
          </p>
          <el-table :data="cancelOrder" v-loading="cancalRq" border
            :header-cell-style="{ background: '#E4ECFD', padding: 0 }">
            <el-table-column :align="item.align" v-for="item in cancelColumns" :key="item.prop" :label="item.label"
              :prop="item.prop" :width="item.width">
              <template v-slot="{ row }">
                <p v-if="item.prop === 'action'">
                  <el-radio-group v-model="row['cause']">
                    <el-radio label="旧机线下发货">旧机线下发货</el-radio>
                    <el-radio label="店员操作退单">店员操作退单</el-radio>
                  </el-radio-group>
                </p>
                <p v-else>
                  {{ row[item.prop] }}
                </p>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 订单已全部收货 -->
        <div class="cancel_text" v-else>
          关联订单已全部确认收货，如需取消可直接操作
        </div>
      </div>
    </GlobalDialog>
    <!-- 强制取消详情 -->
    <el-dialog title="强制取消详情" :visible.sync="isCancelDetail" :before-close="resetCancelDetailData" top="8%"
      width="600px">
      <div class="cancel_dialog">
        <h2>
          说明：强制取消订单后，运单在繁星状态变为已取消，方便店员操作退单或线下发货
        </h2>
        <p class="p_info">操作人：{{ cancelOrderDetail.forceCancelInfo }}</p>
        <p class="p_info">操作时间：{{ cancelOrderDetail.forceCancelTime }}</p>
        <!-- 订单未全部收货  -->
        <div class="cancel_table" v-if="cancelOrderDetail.orderViews.length">
          <p class="cancel_text">未收货取消运单原因：</p>
          <el-table :data="cancelOrderDetail.orderViews" v-loading="cancalRq" border
            :header-cell-style="{ background: '#E4ECFD', padding: 0 }">
            <el-table-column :align="item.align" v-for="item in cancelColumns" :key="item.prop" :label="item.label"
              :prop="item.prop === 'recycleOrderNo'
                  ? 'orderNo'
                  : item.prop === 'action'
                    ? 'forceCancelCause'
                    : item.prop
                " :width="item.width">
            </el-table-column>
          </el-table>
        </div>
        <!-- 订单已全部收货 -->
        <div class="cancel_text" v-else>操作强制取消时，无未收货订单</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="resetCancelDetailData">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 查看旧机照片 -->
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看发货拍照图片" :visible="imgShow" @close="imgClose"
      top="5%">
      <div class="img-box">
        <div class="img-tips-box">
          <div class="img-tips" :class="imgShowOnly ? 'img-tips-default' : 'img-tips-choose'">
            照片
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList.length > 0">
            {{ imgShowIndex + 1 }}/{{ DialogImgList.length }}
          </div>
          <div class="block">
            <viewer :images="DialogImgList">
              <el-carousel trigger="click" height="80vh" @change="getindex">
                <el-carousel-item v-for="item in DialogImgList" :key="item">
                  <div class="sec_img">
                    <img :src="item" alt="" />
                  </div>
                </el-carousel-item>
              </el-carousel>
            </viewer>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>

    <!-- 补充发货凭证 -->
    <el-dialog :close-on-click-modal="false" :width="`${800}px`" title="查看发货拍照图片" :visible="supplementimg" @close="imgClose1"
      top="5%">
      <div class="img-box">
        <div class="img-tips-box">
          <div class="img-tips img-tips-default" style="width: 90px;">
           补充发货凭证
          </div>
        </div>
        <div class="img-show">
          <div class="imgs-num" v-if="DialogImgList1.length > 0">
            {{ imgShowIndex1 + 1 }}/{{ DialogImgList1.length }}
          </div>
          <div class="block">
              <el-carousel trigger="click" height="80vh" @change="getindex1">
                <el-carousel-item v-for="(item,index) in DialogImgList1" :key="index">
                  <div class="sec_img">
                    <img :src="item.sampleImage" alt="" class="imgitem" />
                  </div>
                </el-carousel-item>
              </el-carousel>
          </div>
          <div style="margin-top: 10px;" v-if="DialogImgList1.length > 0">
            <span style="font-weight: bold;color: #333;margin-right: 10px;">{{ DialogImgList1[imgShowIndex1].phoneName }}</span><span style="margin-right: 10px;color: rgb(9, 129, 255);cursor: pointer;text-decoration: underline;" @click="sendDtailClick(DialogImgList1[imgShowIndex1].orderno)">{{ DialogImgList1[imgShowIndex1].orderno }}</span><span style="margin-right: 10px;color: #333;">IMEI:{{ DialogImgList1[imgShowIndex1].imei }}</span><span style="color: #333;">上传时间:{{ DialogImgList1[imgShowIndex1].createTime }}</span>
          </div>
          <!-- <LookImg :images="imgList"/> -->
        </div>
      </div>
    </el-dialog>
    <LogisticsInquiryStatusDia ref="logisticsInquiryStatusDia" @confirm="handleCurrentChange()"
      @close="handleCurrentChange()" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import moment from "moment";
import GlobalDialog from "@/components/global/components/GlobalDialog.vue";
// import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { getRecycleState, setValue } from "@/utils/common";
import LogisticsInquiryStatusDia from "./dialog/LogisticsInquiryStatusDia.vue";

export default {
  name: "Table",
  components: { GlobalDialog, LogisticsInquiryStatusDia },
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    timeoutType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userName: "",
      offlineExpressId: "",
      isOnline: "",
      refresh: false,
      /*********** 图片新增 **********/
      imgShow: false,
      supplementimg: false,
      imgShowOnly: true,
      DialogImgList: [],
      DialogImgList1: [],
      imgShowIndex: 0,
      imgShowIndex1: 0,
      imgIndex: 0,
      storeShow: false,
      viewrecords: false,
      remakeRecordsShow: false,
      storeList: [],
      pcaddPric: null,
      remakeloading: false,
      enquiryDetailsShow: false,
      /*********** end**********/
      expediteLoading: false,
      expediteVisible: false,
      expediteTraceLogColumns: [
        { label: "入库单据", prop: "intoNo" },
        { label: "入库关联原订单号", prop: "recycleOrderNo" },
        { label: "入库机型", prop: "phoneSku" },
        { label: "旧机串号", prop: "imei" },
        { label: "绑定条码", prop: "barCode" },
        { label: "入库仓库", prop: "intoWarehouseName" },
        { label: "入库单位", prop: "intoSupplierName" },
        { label: "入库时间", prop: "intoTime" },
        { slotName: "isSendExpress" },
      ],
      dialogExpediteList: [],
      expediteParams: {},
      state: 1,
      tabArr: {},
      cancalRq: true,
      // 当前操作数据
      currentData: {},
      // 预览数据
      viewerList: [],
      // // 是否显示预览
      // showViewer: false,
      // 取消订单
      cancelOrder: [],
      // 取消动态口令
      cancelCode: "",
      // 强制取消运单
      isCancel: false,
      // 强制取消详情
      isCancelDetail: false,
      // 强制取消详情订单
      cancelOrderDetail: {
        orderViews: [],
        forceCancelTime: "--",
        forceCancelInfo: "--",
      },
      // 取消table
      cancelColumns: [
        {
          prop: "index",
          label: "序号",
          align: "center",
          width: "60px",
        },
        {
          prop: "recycleOrderNo",
          label: "订单编号",
          align: "center",
          width: "180px",
        },
        {
          prop: "state",
          label: "订单状态",
          align: "center",
          width: "100px",
        },
        {
          prop: "action",
          label: "取消原因",
          align: "center",
        },
      ],
      cancelOriginOptions: [
        { label: "门店", value: "00" },
        { label: "平台", value: "01" },
        { label: "第三方", value: "02" },
      ],
      callerChannelOptions: [
        { value: "01", label: "门店下单" },
        { value: "02", label: "平台下单" },
        { value: "03", label: "第三方下单" },
      ],
      initData: null,
      redenvelopeslisttitle: [
        { slotName: "orderNo" },
        { slotName: "phoneName" }, //机型
        { label: "成交商家", prop: "merchantName" },
        { label: "成交商家报价（元）", prop: "quotePrice" },
        { label: "下单店员", prop: "staffName" },
        { slotName: "barCode" },
        { slotName: "state" },
        { slotName: "supplement" },
        { slotName: "caozuo" },
      ],
      loading3: false,
      redenvedata: [],
      page3: {
        pageNum: 1,
        total: 0,
      },
      redenvelopeslist: [
        {
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
        },
        {
          key: "barCode",
          type: "input",
          labelName: "繁星码",
          placeholder: "请输入繁星码",
        },
      ],
      expressMarkState: "",
      handleShow: false,
      loading: false,
      seachDataList: [],
      auditStatus: "00",
      auditStatusRadio: "",
      butLoading: false,
      enableButLoading: false,
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      tableColumns: [],
      defaultTaleColumns: [
        { prop: "receiverMerchantName", label: "收货方" },
        { slotName: "senderCompanyName" }, //门店商户
        { slotName: "senderStoreName" }, //发货门店
        { slotName: "expressCode" }, //发货快递
        { prop: "expressNo", label: "物流单号" },
        { slotName: "singleChannel" }, //下单通道
        { slotName: "callerChannel" }, //下单方式   01 门店小程序，02 主后台
        { prop: "createTime", label: "发货时间" },
        { slotName: "senderName" },
        { slotName: "parcelQuantity" },
        { slotName: "allCost" },

        { slotName: "sendTimeRegion" }, // 预约取件时间
        { slotName: "noPickDay" }, //超时未取件标记
        { slotName: "intoOldNum" }, //仓库入库旧机
        { slotName: "sendProof" }, //发货拍照   2张图片
        { slotName: "stateReal" }, //实际快递状态
        // {slotName: "isForceCancel"},//是否为强制取消
        { slotName: "operation" }, //操作
      ],
      // 弹框
      cancelShow: false,
      RemarksTitle: "",
      applyTitle: "",
      command: "",
      auditNote: "",
      monthSettlementAccount: "",
      RemarksShow: false,
      enableShow: false,
      applyDialogVisible: false,
      enableTitle: "",
      masterNote: "",
      rowExpressNo: "",
      setDialogVisible: false,
      rulesInfo: {},
      expressList: [],
      businessVisible: false,
      canceBtnLoading: false,
      DialogTableColumns: [],
      DialogDataList: [],
      dialogPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      isEnable: "",
      applyRecord: {},
      Logisticsdetails: false,
      Logisticsarray: {},
      loading2: false,
      iiicd: "",
      arr: {},
      initexpressNo: "",
      isTimeoutPick: false,
      isTimeoutSign: false,
      isForceCancel: false,
      isHavNoDelivery: false,
      stateRealOptions: [
        { label: "已下单", value: "1" },
        { label: "进行中", value: "2" },
        // { label: '已完成', value: '6'},
        { label: "已签收", value: "6" },
        { label: "已取消", value: "7" },
        { label: "已删除", value: "99" },
      ],
      phoneName:"",
      orderno:"",
    };
  },
  created() {
    this.refresh = true;
    this.tableColumns = [...this.defaultTaleColumns];
    let expressNo = this.$route.query.id;
    console.log(expressNo);
    if (expressNo) {
      this.initexpressNo = expressNo;
    }
    let startTime = moment()
      .day(moment().day() - 6)
      .format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
    let endTime = moment().format("YYYY-MM-DD 23:59:59");
    startTime = Date.parse(startTime);
    endTime = Date.parse(endTime);
    this.entryTime = [startTime, endTime];
    this.userName = localStorage.getItem("userName");
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    tabIndex(val) {
      this.refresh = false;
      this.setColums(val);
      this.$emit("changeTimeoutType", false);
      this.isTimeoutPick = false;
      this.isHavNoDelivery = false;
      this.isTimeoutSign = false;
      this.isForceCancel = false;

      if (val == 0) {
        this.state = 1;
      } else if (val == 1) {
        this.state = 2;
      } else if (val == 2) {
        this.state = 6;
      } else if (val == 3) {
        this.state = 7;
      } else if (val == "4") {
        this.state = 100;
      }
      this.SeachParams.channel = ''
      this.handleCurrentChange();
      // 在组件移除后，重新渲染组件
      this.refresh = true;
    },
    timeoutType(val) {
      if (this.tabIndex == "0") {
        this.isTimeoutPick = val;
      } else if (this.tabIndex == "1") {
        this.isTimeoutSign = val;
      } else if (this.tabIndex == "3") {
        this.isForceCancel = val;
      } else if (this.tabIndex == "2" || this.tabIndex == "4") {
        this.isHavNoDelivery = val;
      }
      this.handleCurrentChange();
    },
    SeachParams(newVal) {
      console.log("====", newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
  },
  methods: {
    // 筛选仓库
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    filterTag(value, row) {
      console.log(12345, value, row);
      if (row.receiverMerchantName == "繁星分转中心" && value == "00") {
        return row.intoOldNumState == value;
      }
      if (row.receiverMerchantName == "繁星分转中心") {
        if (value == "00") {
          return row.intoOldNumState == value;
        } else {
          return row.intoOldNumState == "01" || row.intoOldNumState == "--";
        }
      } else {
        return;
      }
    },
    openImgBox(row) {
      this.imgShow = true;
      if (row.sendProof1 != "--") {
        this.DialogImgList = [row.sendProof, row.sendProof1];
      } else {
        this.DialogImgList = [row.sendProof];
      }
      console.log("====", this.DialogImgList);
    },
    openImgBox1(row) {
      this.supplementimg = true;
        // this.phoneName=row.phoneName
        // this.orderno=row.orderNo
        // this.DialogImgList1 = this.DialogImgList 
        let arry=[]
        for (let i=0;i<row.deliverGoodImages.length;i++){
          arry.push({
            phoneName:row.phoneName,
            orderno:row.orderNo,
            orderUnique:row.orderUnique,
            createTime:row.deliverGoodImages[i].createTime,
            sampleImage:row.deliverGoodImages[i].sampleImage,
            imei:row.imei
          })
        }
        this.DialogImgList1 = arry
    },
    getindex(e) {
      console.log(e);
      this.imgShowIndex = e;
    },
    getindex1(e) {
      console.log(e);
      this.imgShowIndex1 = e;
    },
    imgClose() {
      console.log(1);
      this.imgShow = false;
      this.imgList = [];
    },
    imgClose1() {
      console.log(1);
      this.supplementimg = false;
    },
    onLockClose() {
      this.expediteVisible = false;
    },

    // 仓库入库旧机
    intoMachine(row) {
      this.expediteVisible = true;
      let params = {
        pageNum: 1,
        pageSize: 10,
        expressNo: row.expressNo,
        expressId: row.id,
      };
      if (this.state == "100") {
        params.isOffline = true;
      }
      _api.intoMachine(params).then((res) => {
        this.dialogExpediteList = res.data.records;
        this.expediteParams.total = res.data.total;
        this.expediteParams.pageNum = res.data.current;
      });
    },
    setColums(index) {
      const columns = [...this.defaultTaleColumns];
      console.log(columns, "前面", index);
      switch (index) {
        case 1:
          columns.splice(
            11,
            6,
            { slotName: "noSignDay" }, //超时未签收标记
            { slotName: "intoOldNum" }, //仓库入库旧机
            { slotName: "sendProof" }, //发货拍照
            { slotName: "stateReal" }, //实际快递状态
            { slotName: "operation" }
          );
          break;
        case 2:
          columns.splice(
            11,
            6,
            {
              prop: "allFee",
              label: "物流费用",
            },
            {
              prop: "signForTime",
              label: "签收时间",
            },
            { slotName: "intoOldNum" }, //仓库入库旧机
            { slotName: "sendProof" }, //发货拍照
            { slotName: "stateReal" }, //实际快递状态
            { slotName: "operation" }
          );
          break;
        case 3:
          columns.splice(
            11,
            6,
            {
              prop: "cancelTime",
              label: "取消时间",
            },
            { slotName: "cancelUserName" }, //取消操作人
            { slotName: "sendProof" }, //发货拍照
            {
              prop: "cancelCause",
              label: "取消原因",
            },
            { slotName: "stateReal" }, //实际快递状态
            { slotName: "isForceCancel" }, //是否为强制取消
            { slotName: "operation" }
          );
          break;
        case 4:
          columns.splice(
            11,
            6,
            { slotName: "noSignDay" }, //已发货标记
            { slotName: "intoOldNum" }, //仓库入库旧机
            { slotName: "sendProof" } //发货拍照
          );
          break;
      }

      this.tableColumns = columns;
    },
    // 清除强制取消数据
    resetCancelData() {
      this.isCancel = false;
      this.cancelOrder = [];
      this.cancelCode = "";
    },
    // 清除强制取消详情数据
    resetCancelDetailData() {
      this.isCancelDetail = false;
      this.cancelOrderDetail = {
        orderViews: [],
        forceCancelTime: "--",
        forceCancelInfo: "--",
      };
    },
    // 取消订单关闭
    cancelClose() {
      this.resetCancelData();
    },
    // 提交强制取消
    cancelSubmit() {
      if (!this.cancelCode) {
        return this.$message.error("请输入谷歌动态口令");
      }
      // 是否所有未收货的订单都设置了原因
      const isCause = this.cancelOrder.every((v) => v.cause);
      if (!isCause) {
        return this.$message.error("所有未收货的订单都必须选择取消原因");
      }
      // 强制取消订单参数
      const cancelCause = this.cancelOrder.map((v) => {
        return {
          cause: v.cause,
          orderNo: v.recycleOrderNo,
        };
      });
      // 快递单号
      const expressNo = this.currentData.expressNo;
      // 谷歌验证
      const command = this.cancelCode;
      _api.cancelForceOrder({ cancelCause, expressNo, command }).then((r) => {
        if (r.code === 1) {
          // 重放数据
          this.resetCancelData();
          // 重新请求接口
          this.handleCurrentChange();
        }
      });
    },
    // 强制取消详情
    canDetailCelBtn(row) {
      this.isCancelDetail = true;
      this.cancelRq = true;
      _api.getCancelForceInfo(row.expressNo).then((r) => {
        if (r.code === 1) {
          // 取消详情不需要考虑已收货情况
          const cancelOrderDetail = {
            ...r.data,
            forceCancelInfo: `${!r.data.forceCancelName ? "" : r.data.forceCancelName
              }${!r.data.forceCancelAcc ? "--" : "-" + r.data.forceCancelAcc}`,
            orderViews: r.data.orderViews.map((v, i) => {
              v = setValue(v);
              return {
                ...v,
                state: getRecycleState(v.state),
                index: i + 1,
              };
            }),
          };
          this.cancalRq = false;
          this.cancelOrderDetail = cancelOrderDetail;
        }
      });
    },
    canCelBtn(row) {
      const expressOrderId = row.expressOrderId;
      this.currentData = row;
      this.isCancel = true;
      this.cancalRq = true;
      _api.recycleOrder({ expressOrderId }).then((r) => {
        if (r.code === 1) {
          const cancelOrder = r.data
            .filter((v) => v.state !== "04" && v.state !== "05")
            .map((v, i) => {
              v = setValue(v);
              return {
                ...v,
                state: getRecycleState(v.state),
                index: i + 1,
              };
            });
          this.cancalRq = false;
          this.cancelOrder = cancelOrder;
        }
      });
      // // 测试数据
      // this.cancalRq = false;
      // const cancelOrder = [1, 2];
      // this.isCancel = true;
      // // 先去除已收货，再重新构建数据
      // this.cancelOrder = (cancelOrder.filter(v => v.state !== '04' && v.state !== '05')).map((v, i) => {
      //   return {
      //     ...v,
      //     state: getRecycleState(v.state),
      //     index: i + 1
      //   }
      // });
    },
    // 订单详情
    goDetails(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo },
      });
      window.open(routeData.href, "_blank");
    },
    allotDialogClosed() { },
    redenvellistFn(val) {
      var pageNum = val ? val : 1;
      this.redenvelopeslistFn(this.arr, pageNum);
    },
    redenvelopeslistFn(data, pageNum) {
      this.arr = data;
      let params = {
        orderNo: data.orderNo,
        barCode: data.barCode,
        expressNo: this.iiicd,
        pageNum: pageNum || 1,
        pageSize: 10,
        offlineExpressId: this.offlineExpressId,
        isOnline: this.isOnline,
        expressMarkState: this.expressMarkState,
      };
      _api.getOrderListByExpressNo(params).then((res) => {
        console.log(res);
        this.redenvedata = res.data.records;
        this.page3.pageNum = res.data.current;
        this.page3.total = res.data.total;
      });
    },
    // 发货旧机
    fahuo(row) {
      console.log("###", row);
      this.handleShow = true;
      this.iiicd = row.expressNo;
      this.offlineExpressId = row.id == "--" ? "" : row.id;
      this.isOnline = this.state != "100" ? true : false;
      this.expressMarkState = "";
      let params = {
        isOnline: this.isOnline,
        offlineExpressId: this.offlineExpressId,
        expressNo: row.expressNo,
      };
      _api.getOrderListByExpressNo(params).then((res) => {
        console.log(res);
        this.redenvedata = res.data.records;
        this.page3.pageNum = res.data.current;
        this.page3.total = res.data.total;
      });
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.page.pageNum = val;
      }
      const baseRequest = {
        merchantId: this.SeachParams.merchantId,
        companyId: this.SeachParams.companyId,
        expressNo: this.SeachParams.expressNo
          ? this.SeachParams.expressNo
          : this.initexpressNo
            ? this.initexpressNo
            : "",
        afterNotSignedDay: this.SeachParams.afterNotSignedDay,
        staffName: this.SeachParams.staffName,
        cancelOrigin: this.state == 7 ? this.SeachParams.cancelOrigin : "",
        stateReal: [1, 2, 6, 7].includes(this.state)
          ? this.SeachParams.stateReal
          : "",
        state: this.state,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        signStartTime: this.state == 6 ? this.SeachParams.signStartTime : "",
        signEndTime: this.state == 6 ? this.SeachParams.signEndTime : "",
        cancelStartTime:
          this.state == 7 ? this.SeachParams.cancelStartTime : "",
        cancelEndTime: this.state == 7 ? this.SeachParams.cancelEndTime : "",
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        expressCode: this.SeachParams.expressCode,
        storeId: this.SeachParams.storeId,
        sendType: this.SeachParams.sendType,
        channel: this.SeachParams.channel,
        isTimeoutPick: this.isTimeoutPick,
        isTimeoutSign: this.isTimeoutSign,
        isForceCancel: this.isForceCancel || null,
        isHavNoDelivery: this.isHavNoDelivery,
      };
      this.$store.commit("tagsView/EXCELPARAMS", baseRequest);
      console.log(baseRequest, "--------------------");
      this.loading = true;
      _api.getExpressOrders(baseRequest).then((res) => {
        console.log("=====", res);
        if (res.code === 1) {
          console.log("[1]", [...res.data.page.records]);

          this.tabArr = {
            waitPick: res.data.waitPick,
            timeoutWaitPick: res.data.timeoutWaitPick,
            inTransit: res.data.inTransit,
            onlineSignError: res.data.onlineSignError,
            signFor: res.data.signFor,
            onlineSignNoTake: res.data.onlineSignNoTake,
            cancel: res.data.cancel,
            offlineSendNum: res.data.offlineSendNum,
            offlineSignNoTake: res.data.offlineSignNoTake,
          };
          this.$emit("getSendData", this.tabArr);

          this.seachDataList = res.data.page.records.map((v) => {
            v = setValue(v);
            switch (v.receiveParty) {
              case "01":
                v.receiveParty = "回收商";
                break;
              case "02":
                v.receiveParty = "平台";
                break;
              default:
                v.receiveParty = "--";
                break;
            }
            return v;
          });
          this.page.total = res.data.page.total;
          this.page.pageNum = res.data.page.current;
          this.loading = false;
          this.initexpressNo = "";
        }
      }).finally(() => {
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    remarksClosed() {
      this.RemarksShow = false;
      this.masterNote = "";
    },
    // 弹出弹框
    handleBtn(row, type) {
      if (type == "see") {
        this.loading2 = true;
        this.Logisticsdetails = true;
        _api.logisticsInfo({ expressNo: row.expressNo }).then((res) => {
          console.log(res);
          this.Logisticsarray = res.data;
          this.loading2 = false;
        });
      } else {
        this.rowExpressNo = row.expressNo;
        this.cancelShow = true;
      }
    },
    optionsFilter(v, options) {
      return options.find((item) => item.value == v)?.label;
    },
    //合作回收商家
    handleCurrentChangeDialog(val) { },
    onClose() { },
    canceClosed() {
      this.cancelShow = false;
      this.rowExpressNo = "";
      this.command = "";
      this.handleCurrentChange();
    },
    canceSubmit() {
      this.canceBtnLoading = true;
      let params = {
        command: this.command,
        expressNo: this.rowExpressNo,
      };
      _api
        .expressOrderCancel(params)
        .then((res) => {
          this.$message.success("操作成功");
          this.cancelShow = false;
          this.canceBtnLoading = false;
        })
        .catch(() => {
          this.canceBtnLoading = false;
        });
    },
    sendDtailClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/ .el-button {
      margin-left: 0;
    }
  }

  .button_top {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .faultBtn {
    width: 500px;
    height: 34px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    cursor: pointer;

    .active {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      background: #0981ff;
      border-radius: 20px;
    }

    .none {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 32px;
      height: 32px;
      border-radius: 20px;
    }
  }

  .verify-title {
    color: #0981ff;
    line-height: 16px;
    font-size: 16px;
    padding-left: 10px;
    border-left: 3px solid;
    margin-bottom: 10px;
  }

  .enable-tip {
    color: #ff8080;
    font-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      font-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mr-50 {
    margin-right: 50px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .font-w {
    font-weight: bold;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex-as {
    display: flex;
    align-items: flex-start;
  }
}

.center_router {
  margin-top: 15px;
  background: #f5f6fa;
  border-radius: 6px;
  padding: 13px 33px 13px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.images-box>img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}

.images-box>span {
  color: #0981ff;
  cursor: pointer;
}

.text-down {
  color: #0981ff;
  cursor: pointer;
  text-decoration: underline;
}

// 弹窗
.img-box {
  margin-bottom: 20px;

  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }

  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;

    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }

    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }

    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }
}

.isdeal {
  background: #ff687b;
  margin-right: 5px;
  display: inline-block;
}

.sec_img {
  width: 20vw;
  height: 80vh;
  margin: auto;

  img {
    width: 20vw;
    height: 80vh;
    object-fit: contain;
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.one {
  flex: 1;
  text-align: center;
}

.one_img {
  width: 38px;
  height: 11px;
}

.fzs18 {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
}

.b25 {
  margin-bottom: 15px;
}

.fzs14 {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #666666;
}

.b03 {
  margin-bottom: 3px;
}

.two {
  max-height: 124px;
  background: #f5f6fa;
  border-radius: 6px;
  width: 360px;
  padding: 10px 13px 15px 13px;
  box-sizing: border-box;
}

.twoinfo {
  font-size: 16px;
  font-family: FZLanTingHei-DB-GBK;
  font-weight: 400;
  color: #0981ff;
  margin-bottom: 6px;
}

.twophone {
  font-size: 14px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-bottom: 6px;
}

.loacinfo {
  font-size: 16px;
  font-family: FZLanTingHei-M-GBK;
  font-weight: 400;
  color: #333333;
  margin-top: 15px;
}

.three {
  min-height: 100px;
  background: #f9fbfd;
  border: 1px solid #c1d1ff;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 16px;
  max-height: 200px;
  overflow: auto;
  padding: 20px 20px;
  box-sizing: border-box;
  padding-bottom: 0;
}

.three::-webkit-scrollbar {
  width: 3px;
  // display: none;
}

/*定义滚动条轨道*/
.three::-webkit-scrollbar-track {
  border-radius: 10px;
  // display: none;
}

/*定义滑块*/
.three::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(27, 140, 236, 0.5);
  display: none;
}

.dainzhang {
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype2 {
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.channeltype3 {
  display: flex;
  align-items: center;
  height: 16px;
  background: #fba516;
  border-radius: 4px;
  padding: 2px;
  font-size: 12px;
  color: #ffffff;
  margin-right: 6px;
}

.cance-tip {
  color: #ff8080;
  font-size: 14px;
  margin-bottom: 20px;

  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
  }
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mr-50 {
  margin-right: 50px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.font-w {
  font-weight: bold;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-jb {
  display: flex;
  justify-content: space-between;
}

.flex-as {
  display: flex;
  align-items: flex-start;
}

.cancel_dialog {
  width: 100%;

  >h2 {
    font-weight: 900;
    font-size: 12px;
    color: #ff687b;
  }

  >p {
    font-size: 14px;
    margin-top: 20px;
    color: #111;
    font-weight: 900;
    text-align: center;
  }

  .p_info {
    text-align: left;
    font-size: 14px;
    margin-top: 10px;
    color: #111;
    font-weight: 900;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  >.code_box {
    margin-top: 30px;
    width: 60%;
  }

  .cancel_table {
    margin-bottom: 30px;
  }

  .cancel_text {
    margin-bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 900;
  }

  /deep/.el-icon-arrow-down {
    /deep/::before {
      content: "";
      display: block;
      font-size: 14px;
      visibility: hidden;
    }
  }
}
.sec_img{
  display: flex;
  justify-content: center;
}
</style>
